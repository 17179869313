export default [
  {
    path: "/",
    name: "landing-page",
    meta: {
      title: "Kaldera Order Ticket",
    },
    component: () => import("../views/pages/landing.vue"),
  },
  {
    path: "/order",
    name: "order-page",
    meta: {
      title: "Pesan Tiket Masuk Danau Toba",
    },
    component: () => import("../views/pages/order.vue"),
  }
];
