import axios from 'axios';
const API_BASE = process.env.VUE_APP_API_HOST;

export const state = {
  products: [],
  parkings: [],
  checkoutLoading: false,
  paymentUrl: ''
};

export const mutations = {
  setProducts(state, data) {
    state.products = data;
  },
  setParkings(state, data) {
    state.parkings = data;
  },
  setCheckoutLoading(state, value) {
    state.checkoutLoading = value;
  },
  setPaymentUrl(state, value) {
    state.paymentUrl = value;
  }
};

export const actions = {
  async fetchProduct({commit}, {date, nationality} = {}) {
    try {
      const response = await axios.get(`${API_BASE}/products?date=${date}&nationality=${nationality}`);
      commit('setProducts', response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
  async fetchParking({commit}) {
    try {
      const response = await axios.get(`${API_BASE}/products?type=parking`);
      commit('setParkings', response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
  async createOrder({commit}, body) {
    commit('setCheckoutLoading', true)
    try {
      const response = await axios.post(`${API_BASE}/order/_create`, body);
      commit('setCheckoutLoading', false)
      commit('setPaymentUrl', response.data.data.paymentUrl)
    } catch (error) {
      commit('setCheckoutLoading', false)
      console.error('Error fetching data:', error);
    }
  }
};
