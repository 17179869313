import axios from 'axios';
const API_BASE = process.env.VUE_APP_API_HOST;

export const state = {
  checkoutLoading: false,
  paymentUrl: ''
};

export const mutations = {
  setCheckoutLoading(state, value) {
    state.checkoutLoading = value;
  },
  setPaymentUrl(state, value) {
    state.paymentUrl = value;
  }
};

export const actions = {
  async createOrder({commit}, body) {
    commit('setCheckoutLoading', true)
    try {
      const response = await axios.post(`${API_BASE}/order/_create`, body);
      commit('setCheckoutLoading', false)
      commit('setPaymentUrl', response.data.data.paymentUrl)
    } catch (error) {
      commit('setCheckoutLoading', false)
      console.error('Error fetching data:', error);
    }
  }
};
