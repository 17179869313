import axios from 'axios';
const API_BASE = process.env.VUE_APP_API_HOST;

export const state = {
  countries: []
};

export const mutations = {
  setCountries(state, data) {
    state.countries = data;
  }
};

export const actions = {
  async fetchCountries({commit}) {
    try {
      const response = await axios.get(`${API_BASE}/countries`);
      const countries = []
      countries.push({ value: null, text: 'Select Country' })
      response.data.data.forEach(country => {
        countries.push({
          value: country.name,
          text: country.name
        })
      });
      commit('setCountries', countries);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
};
