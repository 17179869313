import axios from 'axios';
const API_BASE = 'https://ibnux.github.io/data-indonesia'

export const state = {
  provinces: [],
  cities: []
};

export const mutations = {
  setProvinces(state, data) {
    state.provinces = data;
  },
  setCities(state, data) {
    state.cities = data;
  }
};

export const actions = {
  async fetchProvinces({commit}) {
    try {
      const response = await axios.get(`${API_BASE}/provinsi.json`);
      const provinces = []
      provinces.push({ value: null, text: 'Select Province' })
      response.data.forEach(province => {
        provinces.push({
          value: {id: province.id, name: province.nama},
          text: province.nama
        })
      });
      commit('setProvinces', provinces);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },
  async fetchCities({commit}, provinceId) {
    try {
      const response = await axios.get(`${API_BASE}/kabupaten/${provinceId}.json`);
      const cities = []
      cities.push({ value: null, text: 'Select City' })
      response.data.forEach(city => {
        cities.push({
          value: {id: city.id, name: city.nama},
          text: city.nama
        })
      });
      commit('setCities', cities);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
};
